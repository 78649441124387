import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const VisualCTA = memo(function VisualCTA({ items }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      {items.slice(0, 2).map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 0 10vw;

  @media (max-width: 1199px) {
    padding: 0 8.454vw;
  }

  @media (max-width: 1023px) {
    padding: 0;
  }
`

import styled from '@emotion/styled'
import { DigitalConcierge } from 'app/components/DigitalConcierge'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { ImagesParagraph } from 'app/components/ImagesParagraph'
import { Info } from 'app/components/Info'
import { Intro } from 'app/components/Intro'
import { OffersBanner } from 'app/components/OffersBanner'
import { Review } from 'app/components/Review'
import { SEO } from 'app/components/SEO'
import { SpaTour } from 'app/components/SpaTour'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { VisualCTA } from 'app/components/VisualCTA'
import { ZigZagVisual } from 'app/components/ZigZagVisual'
import { BluSpaQuotationForm } from 'app/containers/BluSpaQuotationForm'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import {
  BluSpaQuotationFormSenderBackend,
  BluSpaQuotationFormSenderBackendConfiguration,
} from 'app/utils/BluSpaQuotationFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  bluSpaQuotationFormSenderBackendConfiguration: BluSpaQuotationFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function BluSpaPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      BluSpaQuotationFormSenderBackend(
        pageContext.bluSpaQuotationFormSenderBackendConfiguration,
      ),
    [pageContext.bluSpaQuotationFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="dark" {...context.introProps} />
      ) : null}
      {/* {context.imagesParagraphProps ? (
        <ImagesParagraph {...context.imagesParagraphProps} />
      ) : null} */}
      {context.spaTourProps ? <SpaTour {...context.spaTourProps} /> : null}
      {context.zigZagVisualProps ? (
        <ZigZagVisual {...context.zigZagVisualProps} />
      ) : null}
      {context.galleryImagesProps ? (
        <GalleryImages {...context.galleryImagesProps} />
      ) : null}
      {context.infoProps ? (
        <Info variant="dark" {...context.infoProps} />
      ) : null}
      {context.offersBannerProps ? (
        <OffersBanner variant="dark" {...context.offersBannerProps} />
      ) : null}
      <BluSpaQuotationForm
        {...(context.bluSpaQuotationFormProps
          ? context.bluSpaQuotationFormProps
          : null)}
        onBluSpaQuotationFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
      />
      {context.reviewProps ? <Review {...context.reviewProps} /> : null}
      {context.visualCTAProps ? (
        <VisualCTA {...context.visualCTAProps} />
      ) : null}
      <MediaContextProvider>
        <Media greaterThanOrEqual="desktopSmall">
          {context.digitalConciergeProps ? (
            <DigitalConcierge {...context.digitalConciergeProps} />
          ) : null}
        </Media>
      </MediaContextProvider>
      {context.footerProps ? (
        <Footer
          appTitle={
            context.digitalConciergeProps
              ? context.digitalConciergeProps.title
              : null
          }
          appSubtitle={
            context.digitalConciergeProps
              ? context.digitalConciergeProps.subtitle
              : null
          }
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``

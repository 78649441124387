import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  images: ItemProps[]
  title?: string
}

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export const SpaTour = memo(function SpaTour({ images, title }: Props) {
  const settings = {
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    variableWidth: true,
  }

  if (images.length < 1) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {title ? (
          <Fade left distance="7.5rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
      </LeftSide>
      {images ? (
        <Carousel>
          <Fade right distance="7.5rem">
            <Slider {...settings}>
              {uniq(images).map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </Slider>
          </Fade>
        </Carousel>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 8.75rem 0 12.5rem;
  &:before {
    content: '';
    width: 22.222vw;
    height: 13.889vw;
    max-width: 512px;
    max-height: 417px;
    background: url('/wave.svg') no-repeat;
    background-size: cover;
    opacity: 0.16;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding: 5.625rem 0 7.5rem;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 0;
    &:before {
      display: none;
    }
  }
`

const LeftSide = styled.div`
  width: 45%;
  padding: 0 6.667vw 0 10vw;

  @media (max-width: 1199px) {
    padding-left: 8.454vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 8.454vw;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 400;
  line-height: 5.25rem;
  transform: translateY(-1rem);

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Carousel = styled.div`
  width: 55%;
  position: relative;
  .react-reveal {
    height: 100%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 2.25rem;

    .slick-slider {
      padding: 0 8.454vw;
    }
    .slick-list {
      overflow: visible;
    }
    .slick-track {
      display: flex;
    }
  }
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: -10.694vw;
  z-index: 3;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.slider-prev {
    bottom: 0;
  }
  &.slider-next {
    bottom: 6.75rem;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

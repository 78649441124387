import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title?: string
}

export const Item = memo(function Item({ image, title }: Props) {
  return (
    <Container>
      {image ? <Image {...image} /> : null}
      {title ? <Title>{title}</Title> : null}
    </Container>
  )
})

const Container = styled.div`
  width: 22.222vw;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-right: 1.5rem;
  padding-bottom: 124%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 50vw;
    margin: 0 0.375rem;
  }

  @media (max-width: 767px) {
    width: calc(83.092vw - 0.75rem);
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  position: absolute;
  bottom: 2.25rem;
  right: 2.25rem;
  left: 2.25rem;
  z-index: 2;

  @media (max-width: 1023px) {
    bottom: 1.875rem;
    right: 1.875rem;
    left: 1.875rem;
  }
`
